import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function SattvaReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Salarpuria Sattva Developers</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">Built on the bedrock of trust, innovation, and knowledge leadership, the Sattva Group is one of India’s leading Real Estate Developers and Consulting organizations. Founded in 1993, the Group has set its footprints across India and is one of the most preferred brands in the country today.</p>

            </p>

            {isShowMore && (
                <p className="mb-0">
                    <p className="AboutExpoPara">With a humble beginning in Bengaluru India in 1993, acquiring a small plot that offered just 20000 sq ft. of construction, today the Sattva group has taken giant steps to becoming a builder of immense magnitude. With 3 decades of unwavering commitment to quality construction across 7 cities in India, the Group has completed 69 million sq ft of construction with 39 million sq ft in the pipeline and 28 new projects coming up soon. An arduous journey marked by a steely resolve to deliver to the highest standards of international quality, be it commercial or residential spaces.</p>
                    <p className="AboutExpoPara">A pioneering spirit has led the Sattva group to transition through a course of natural evolution, into a cache of business verticals. From elegant residences with world-class amenities to colossal award-winning commercial spaces, cutting-edge IT parks, co-working, co-living, data centers, warehousing, and education, the Sattva brand is a familiar sight in the metropolises of India and one that marks India’s mercurial rise in the world’s urban landscape.</p>

                    <p className="AboutExpoPara">Evolving as a trusted brand, its foray into diverse verticals has been paved with innovation and shaped by thought leadership. Today, the Group stands tall as a frontrunner, shaping India’s inevitable growth story.</p>
                </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default SattvaReadMore;